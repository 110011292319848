import OnDemandThankYouPage from "components/thank-you/on-demand-video/OnDemandThankYouPage";
import React from "react";

export default function ThankYouPageRoot() {
  return (
    <OnDemandThankYouPage
      metaTitle="Secure Self-Storage Facilities From Anywhere: Webinar"
      metaDescription="Learn how you can share in the success of StorQuest by deterring break-ins & securing your facility in this short, info-packed webinar."
      videoId="i49w61domc"
      title="Self-Storage Solutions: How StorQuest Deters Break-Ins and Secures Their Facilities"
      description="From trespassing and theft to false alarms and siloed systems, self-storage facilities face unique security challenges. Modern physical security technology introduces powerful, easy-to-use solutions that make protecting your facilities efficient and easy."
    />
  );
}
